.mockup {
  position: relative;
  width: 299px;
}

.i-share-gif {
  position: absolute;
  top: 64px;
  left: 11px;
}

.phone-mockup {
  position: relative;
  width: 100%;
  height: auto;
}
