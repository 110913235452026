.home {
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gap {
  height: 5vh;
  width: 100%;
  background-color: var(--forth-color);
}

/* @media (max-width: 670px) {
  .gap {
    height: 1px;
  }
} */
