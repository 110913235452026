.mockup {
  position: absolute;
}

#gamepad-address {
  position: absolute;
  top: 5%;
  left: 30%;
  width: auto;
  height: 7%;
  border-radius: 20px;
}

#gamepad-mockup {
  width: 100%;
  height: 100%;
}

#gamepad-opacity {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12.7% 12.7% 14% 14%/ 19% 19% 22% 22%;
}

#roadtrain-gif {
  position: absolute;
  top: 21%;
  left: 19.5%;
  width: 60.7%;
  height: 58%;
}
