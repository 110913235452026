.auto-scroll {
  width: 90vw;
  min-width: 670px;
  min-height: 550px;
  display: flex;
  flex-wrap: wrap-reverse;
}

.right-column .content .simple-link {
  text-decoration: none;
  font-style: italic;
}

.right-column {
  position: relative;
  color: var(--text-color);
  width: 38.2%;
  min-height: var(--frame-height);
  background-color: var(--first-color);
  font-style: normal;
  font-weight: normal;
}

.right-column .content {
  position: relative;
  margin: 0 auto 0 13.3%;
  height: 100%;
  width: 55.5%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.right-column .content h1 {
  position: absolute;
  white-space: nowrap;
  font-size: var(--header-font);
  top: 12.2%;
}

.right-column .content p {
  position: absolute;
  font-size: var(--normal-font);
  width: 100%;
  line-height: calc(var(--normal-font) * 1.71);
}
.right-column .content .first-p {
  top: 21%;
}

.right-column .content .second-p {
  /* top: 63.7%; */
  top: 62%;
}

.right-column .content div {
  position: absolute;
  color: var(--gray-color);
  font-size: var(--small-font);
  line-height: calc(var(--small-font) * 2);
  top: 75.1%;
}

.right-column .content .try-it {
  position: absolute;
  width: 62.1%;
  height: calc(var(--small-font) * 2.5);
  top: 49.7%;
  left: 0%;
}

.code {
  position: absolute;
  width: 30%;
  font-size: var(--small-font);
  height: calc(var(--small-font) * 2);
  background: none;
  top: 81%;
  left: 0%;
}

.left-square {
  z-index: 2;
  position: relative;
  width: 61.8%;
  min-height: var(--frame-height);
  background-color: var(--second-color);
}

.left-square .mockup {
  position: relative;
  width: 95.6%;
  /* height: 77.5%; */
  margin: 12.2% -2% 10.3% auto;
}

@media (max-width: 670px) {
  .auto-scroll {
    width: 100vw;
    min-width: 0px;
  }
  .right-column {
    width: 100%;
    min-height: 500px;
  }
  .left-square {
    width: 100%;
    height: 100%;
  }

  .left-square .mockup {
    margin: -3.1% auto 10% auto;
    width: 90vw;
  }

  .right-column .content {
    margin: 0px auto;
  }
}
