.my-story {
  background-color: var(--white-color);
  width: 90vw;
}

.content {
  margin: 15vw auto 15vw auto;
  width: 70%;
  font-family: Playfair Display, serif;
  font-style: italic;
  font-weight: normal;
  font-size: var(--normal-font);
  line-height: calc(var(--normal-font) * 2);
}

.content h2 {
  font-size: var(--header-font);
  margin-bottom: calc(var(--normal-font) * 2);
}

.content p {
  margin-bottom: calc(var(--normal-font) * 0.7);
}

@media (max-width: 670px) {
  .my-story {
    width: 100vw;
    min-width: 0px;
  }
}
