.i-share {
  width: 90vw;
  min-width: 670px;
  display: flex;
  flex-wrap: wrap;
}

.left-column {
  position: relative;
  color: var(--text-color);
  width: 38.2%;
  min-height: var(--frame-height);
  background-color: var(--white-color);
  font-style: normal;
  font-weight: normal;
}

.left-column .content {
  position: relative;
  margin: 0 10% 0 auto;
  height: 100%;
  width: 50.2%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.left-column .content h1 {
  position: absolute;
  white-space: nowrap;
  font-size: var(--header-font);
  top: 17.3%;
}

.left-column .content p {
  position: absolute;
  font-size: var(--normal-font);
  width: 100%;
  line-height: calc(var(--normal-font) * 1.71);
  top: 24.4%;
}

.left-column .content div {
  position: absolute;
  color: var(--gray-color);
  font-size: var(--small-font);
  line-height: calc(var(--small-font) * 2);
  top: 61.8%;
}

.left-column .content .try-it {
  position: absolute;
  width: 62.1%;
  height: calc(var(--small-font) * 2.5);
  top: 63.1%;
  right: 0%;
}

.right-square {
  position: relative;
  width: 61.8%;
  min-height: var(--frame-height);
  background-color: var(--third-color);
}

.right-square .mockup {
  position: relative;
  margin: 8.8% auto 8.8% 11%;
}

.mockup img {
  cursor: pointer;
}

@media (max-width: 670px) {
  .i-share {
    width: 100vw;
    min-width: 0px;
  }
  .left-column {
    width: 100%;
    min-height: 500px;
  }
  .right-square {
    width: 100%;
  }

  .right-square .mockup {
    margin-left: auto;
    margin-top: -8.8%;
  }

  .left-column .content {
    margin: 0px auto;
  }
}
