.header {
  height: var(--frame-height);
  width: var(--frame-width);
  display: flex;
}

.left-square {
  position: relative;
  color: var(--text-color);
  width: 61.8%;
  height: 100%;
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
}

.left-square .center-text {
  position: relative;
  top: 42.3%;
  height: 38.7%;
}

.left-square .center-text h1 {
  text-align: center;
  font-size: var(--large-font);
  font-style: normal;
  font-weight: normal;
}

.left-square .center-text h3 {
  position: absolute;
  color: var(--gray-color);
  font-size: var(--header-font);
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
}

.left-square .center-text > h3:nth-child(2) {
  bottom: 19.8%;
}

.left-square .center-text > h3:nth-child(3) {
  bottom: 0%;
}

.right-column {
  width: 38.2%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.right-top {
  position: relative;
  width: 100%;
  height: 61.8%;
  background-color: var(--first-color);
}

.right-bottom .htmlcssjs {
  position: absolute;
  width: 25.9%;
  height: auto;
  left: 15.47%;
  top: 12.31%;
}

.right-bottom {
  position: relative;
  width: 100%;
  height: 39%;
  background-color: var(--second-color);
}

.right-top .reactjs {
  position: absolute;
  width: 18.6%;
  height: auto;
  left: 19.3%;
  top: 69.3%;
}

.right-top .firebase {
  position: absolute;
  width: 28.3%;
  height: auto;
  left: 30.9%;
  top: 71.2%;
}

.right-top .gcp {
  position: absolute;
  width: 16.9%;
  height: auto;
  left: 54.3%;
  top: 69.3%;
}
