.footer {
  position: relative;
  width: 90vw;
  /* min-width: 670px; */
  min-height: 400px;
  display: flex;
  flex-wrap: wrap-reverse;
}

.left-column {
  position: relative;
  color: var(--text-color);
  width: 38.2%;
  min-height: var(--frame-height);
  background-color: var(--forth-color);
  font-style: normal;
  font-weight: normal;
}

.left-column h6 {
  position: absolute;
  color: var(--white-color);
  font-size: var(--small-font);
  font-style: normal;
  font-weight: lighter;
  bottom: 1%;
  right: 2%;
}

.left-column .photo-container {
  position: relative;
  width: 71.4%;
  height: 30%;
  margin-top: 4.2%;
  margin-bottom: 10%;
}
.makan {
  max-height: 100%;
  max-width: 100%;
}

.left-column .layout {
  position: relative;
  width: 40%;
  height: 35%;
}

.github {
  position: absolute;
  width: 15%;
  top: 0%;
}

.linkdin {
  position: absolute;
  width: 13%;
  top: 26%;
}

.stackoverflow {
  position: absolute;
  width: 45%;
  top: 50%;
}

.twitter {
  position: absolute;
  width: 15%;
  top: 0;
  right: 0;
}

.medium {
  position: absolute;
  width: 15%;
  top: 25%;
  right: 0;
}

.youtube {
  position: absolute;
  width: 45%;
  top: 36%;
  right: -27%;
}

.right-square .content {
  position: relative;
  margin: 0 auto 0 8.2%;
  height: 100%;
  width: 40.3%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
}

.right-square .content h1 {
  position: absolute;
  white-space: nowrap;
  font-size: var(--header-font);
  top: 17%;
}

.right-square .content p {
  position: absolute;
  font-size: var(--normal-font);
  width: 100%;
  line-height: calc(var(--normal-font) * 1.71);
}
.right-square .content .first-p {
  top: 27.6%;
}

.right-square .content .second-p {
  top: 39.4%;
}

.right-square .content .email-me {
  position: absolute;
  width: 47.8%;
  height: calc(var(--small-font) * 2.5);
  color: var(--text-color);
  top: 61%;
  right: 0;
}

.right-square {
  z-index: 2;
  position: relative;
  width: 61.8%;
  min-height: var(--frame-height);
  background-color: var(--white-color);
}

@media (max-width: 670px) {
  .footer {
    width: 100vw;
    min-width: 0px;
  }
  .left-column {
    width: 100%;
    min-height: 700px;
  }

  .left-column .layout,
  .photo-container {
    left: 5%;
  }

  .right-square {
    width: 100%;
    height: 100%;
  }

  .right-square .content {
    min-height: 700px;
  }
}
