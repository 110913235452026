.roadtrain {
  position: relative;
  width: 90vw;
  min-width: 670px;
  min-height: 600px;
  display: flex;
  flex-wrap: wrap;
}

.bottom-color-right,
.bottom-color-left {
  position: absolute;
  width: 100%;
  height: 38.2%;
  left: 0;
  top: 61.8%;
  background-color: var(--third-color);
}

.left-column {
  position: relative;
  color: var(--text-color);
  width: 38.2%;
  min-height: var(--frame-height);
  background-color: var(--white-color);
  font-style: normal;
  font-weight: normal;
}

.left-column .content {
  position: relative;
  margin: 0 2.6% 0 auto;
  height: 100%;
  width: 65.2%;
  min-width: 224px;
  display: flex;
  flex-direction: column;
}

.left-column .content h1 {
  position: absolute;
  white-space: nowrap;
  font-size: var(--header-font);
  top: 12.2%;
}

.left-column .content p {
  position: absolute;
  font-size: var(--normal-font);
  width: 100%;
  line-height: calc(var(--normal-font) * 1.71);
}
.left-column .content .first-p {
  top: 20.7%;
}

.left-column .content .second-p {
  top: 33.4%;
}

.left-column .content div {
  position: absolute;
  color: var(--gray-color);
  font-size: var(--small-font);
  line-height: calc(var(--small-font) * 2);
  top: 66.2%;
}

.left-column .content .try-it {
  position: absolute;
  width: 47.8%;
  height: calc(var(--small-font) * 2.5);
  top: 66.2%;
  right: 0;
}

.code {
  position: absolute;
  width: 25.9%;
  font-size: var(--small-font);
  height: calc(var(--small-font) * 2);
  background: none;
  bottom: 12.2%;
}

.right-square {
  z-index: 2;
  position: relative;
  width: 61.8%;
  min-height: var(--frame-height);
  background-color: var(--white-color);
}

.right-square .mockup {
  bottom: 26.9%;
  left: 13.2%;
  width: 64.8%;
  cursor: pointer;
}

@media (max-width: 670px) {
  .roadtrain {
    width: 100vw;
    min-width: 0px;
  }
  .left-column {
    width: 100%;
    min-height: 500px;
  }
  .right-square {
    width: 100%;
    height: 100%;
    background-color: var(--third-color);
  }

  .right-square .mockup {
    bottom: 20%;
    left: 5vw;
    width: 90vw;
  }

  .left-column .content {
    margin: 0px auto;
  }

  .bottom-color-left {
    background-color: var(--white-color);
  }
}
