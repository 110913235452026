.mockup {
  position: relative;
  width: 650px;
  height: auto;
}

.mac-mockup {
  position: relative;
  width: 100%;
  height: 100%;
}

.backlight {
  position: absolute;
  top: 4.15;
  left: 3.52%;
  width: 92.96%;
  height: 65.55%;
  background-color: black;
}

.auto-scroll-gif {
  position: absolute;
  top: 4.17%;
  left: 3.53%;
  width: 93%;
  height: 65.47%;
}

.gif-opacity {
  position: absolute;
  top: 4.17%;
  left: 3.53%;
  width: 93%;
  height: 65.47%;
  /* background-color: rgba(0, 0, 0, 0.2); */
}

.mockup .gif-opacity {
  cursor: pointer;
}
